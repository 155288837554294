var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-card",
    { staticClass: "content-block pagebreak", attrs: { shadow: "never" } },
    [
      _c(
        "div",
        { staticClass: "heading" },
        [
          _c("div", { staticClass: "grip" }, [_vm._v("⣿")]),
          _c("div", { staticClass: "divider" }, [
            _c("span", [_vm._v("Page break")]),
            _c("hr"),
          ]),
          !_vm.read_only
            ? _c("el-button", {
                staticClass: "control",
                attrs: { plain: "", type: "danger", icon: "el-icon-delete" },
                on: { click: _vm.deleteBlock },
              })
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }