var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.is_template
    ? _c(
        "div",
        [
          _c("placeholder-block", {
            attrs: { label: "Module List", block: _vm.block },
            on: { delete: _vm.deleteBlock },
          }),
        ],
        1
      )
    : _c(
        "div",
        [
          _c(
            "el-card",
            {
              staticClass: "content-block estimate-summary",
              attrs: { shadow: "never" },
            },
            [
              _c(
                "div",
                {
                  staticClass: "heading",
                  attrs: { slot: "header" },
                  slot: "header",
                },
                [
                  _c("div", { staticClass: "grip" }, [_vm._v("⣿")]),
                  _c("span", { staticClass: "label" }, [_vm._v("Module list")]),
                  _c(
                    "el-tooltip",
                    { attrs: { content: _vm.fetchTooltip } },
                    [
                      _c(
                        "el-popconfirm",
                        {
                          attrs: {
                            title:
                              "Replace text block with fetched list of project modules?",
                            disabled: _vm.loading,
                          },
                          on: { confirm: _vm.getModules },
                        },
                        [
                          _c("el-button", {
                            staticClass: "control",
                            attrs: {
                              slot: "reference",
                              icon: "el-icon-refresh",
                              plain: "",
                            },
                            slot: "reference",
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("el-button", {
                    staticClass: "control",
                    attrs: {
                      plain: "",
                      type: "danger",
                      icon: "el-icon-delete",
                    },
                    on: { click: _vm.deleteBlock },
                  }),
                ],
                1
              ),
              _c("text-editor", {
                ref: "content_editor",
                attrs: {
                  content: _vm.editingBlock.content,
                  floating_menu: false,
                  drag_drop: false,
                },
                on: {
                  "update:content": [
                    function ($event) {
                      return _vm.$set(_vm.editingBlock, "content", $event)
                    },
                    _vm.updateBlock,
                  ],
                },
              }),
            ],
            1
          ),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }