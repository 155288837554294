var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.is_template
    ? _c("placeholder-block", {
        attrs: { label: "Jira List", block: _vm.block },
        on: { delete: _vm.deleteBlock },
      })
    : _c(
        "el-card",
        {
          staticClass: "content-block estimate-summary",
          attrs: { shadow: "never" },
        },
        [
          _c(
            "div",
            {
              staticClass: "heading",
              attrs: { slot: "header" },
              slot: "header",
            },
            [
              _c("div", { staticClass: "grip" }, [_vm._v("⣿")]),
              _c("span", { staticClass: "label" }, [_vm._v("Jira list")]),
              _c(
                "div",
                { staticClass: "controls" },
                [
                  _c("el-date-picker", {
                    staticClass: "date-picker",
                    attrs: {
                      type: "daterange",
                      size: "large",
                      placeholder: "Start date",
                      format: "dd/MM/yyyy",
                      "value-format": "yyyy-MM-dd",
                      disabled: !!_vm.editingBlock.content,
                    },
                    on: { change: _vm.updateDateRange },
                    model: {
                      value: _vm.editingBlock.dateRange,
                      callback: function ($$v) {
                        _vm.$set(_vm.editingBlock, "dateRange", $$v)
                      },
                      expression: "editingBlock.dateRange",
                    },
                  }),
                  _c("el-switch", {
                    attrs: { "active-text": "Generate Hyperlinks" },
                    model: {
                      value: _vm.useHyperlinks,
                      callback: function ($$v) {
                        _vm.useHyperlinks = $$v
                      },
                      expression: "useHyperlinks",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-tooltip",
                { attrs: { content: _vm.fetchTooltip } },
                [
                  _c(
                    "el-popconfirm",
                    {
                      attrs: {
                        title:
                          "Replace text block with fetched list of jira tickets?",
                        disabled:
                          _vm.loading || !_vm.$store.getters.is_jira_enabled,
                      },
                      on: { confirm: _vm.getJiraTickets },
                    },
                    [
                      _c("el-button", {
                        staticClass: "control",
                        attrs: {
                          slot: "reference",
                          icon: "el-icon-refresh",
                          plain: "",
                        },
                        slot: "reference",
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("el-button", {
                staticClass: "control",
                attrs: { plain: "", type: "danger", icon: "el-icon-delete" },
                on: { click: _vm.deleteBlock },
              }),
            ],
            1
          ),
          _vm.loading ? _c("el-skeleton", { attrs: { rows: 3 } }) : _vm._e(),
          _c("text-editor", {
            ref: "content_editor",
            attrs: {
              content: _vm.editingBlock.content,
              floating_menu: false,
              drag_drop: false,
            },
            on: {
              "update:content": [
                function ($event) {
                  return _vm.$set(_vm.editingBlock, "content", $event)
                },
                _vm.updateBlock,
              ],
            },
          }),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }