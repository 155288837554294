var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.is_template
    ? _c(
        "div",
        [
          _c("placeholder-block", {
            attrs: {
              label: "Cover sheet",
              block: _vm.block,
              can_delete: false,
            },
          }),
        ],
        1
      )
    : _c(
        "div",
        [
          _c(
            "el-card",
            {
              staticClass: "content-block coversheet",
              attrs: { shadow: "never" },
            },
            [
              _c(
                "div",
                {
                  staticClass: "heading",
                  attrs: { slot: "header" },
                  slot: "header",
                },
                [
                  _c("div", { staticClass: "grip" }, [_vm._v("⣿")]),
                  _c("span", [_vm._v("Cover sheet")]),
                ]
              ),
              !_vm.estimate
                ? _c("el-skeleton", { attrs: { rows: 2, animated: "" } })
                : _c("div", [
                    _c("div", { staticClass: "title" }, [
                      _vm._v(_vm._s(_vm.estimate.title)),
                    ]),
                    _c("div", { staticClass: "desc" }, [
                      _c("span", [_vm._v(_vm._s(_vm.estimate.ref))]),
                      _vm.estimate.description
                        ? _c("span", [
                            _vm._v(" | "),
                            typeof _vm.estimate.description === "string"
                              ? _c("span", [
                                  _vm._v(
                                    " " + _vm._s(_vm.estimate.description) + " "
                                  ),
                                ])
                              : _c("span", { staticClass: "invalid" }, [
                                  _vm._v(
                                    " legacy description requires updating (will not be included in output) "
                                  ),
                                ]),
                          ])
                        : _vm._e(),
                    ]),
                    _c("div", { key: _vm.client?.id, staticClass: "client" }, [
                      _c("div", { staticClass: "label" }, [
                        _vm._v("Client address"),
                      ]),
                      _c("div", { staticClass: "name" }, [
                        _vm._v(_vm._s(_vm.client?.name)),
                      ]),
                      _c("div", { staticClass: "address" }, [
                        _vm.clientAddress.address
                          ? _c("span", [
                              _vm._v(" " + _vm._s(_vm.clientAddress.address)),
                              _c("br"),
                            ])
                          : _vm._e(),
                        _c("span", [
                          _vm._v(
                            " " +
                              _vm._s(_vm.clientAddress.city) +
                              ", " +
                              _vm._s(_vm.clientAddress.region) +
                              " " +
                              _vm._s(_vm.clientAddress.postcode) +
                              " "
                          ),
                        ]),
                      ]),
                    ]),
                  ]),
            ],
            1
          ),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }