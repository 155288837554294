var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.is_template
    ? _c("placeholder-block", {
        attrs: { label: "Cover sheet", block: _vm.block, can_delete: false },
      })
    : _c(
        "el-card",
        { staticClass: "content-block coversheet", attrs: { shadow: "never" } },
        [
          _c(
            "div",
            {
              staticClass: "heading",
              attrs: { slot: "header" },
              slot: "header",
            },
            [
              _c("div", { staticClass: "grip" }, [_vm._v("⣿")]),
              _c("span", [_vm._v("Summary cover sheet")]),
            ]
          ),
          !_vm.project
            ? _c("el-skeleton", { attrs: { rows: 1, animated: "" } })
            : _c("div", [
                _c("div", { staticClass: "title" }, [
                  _vm._v("Project - " + _vm._s(_vm.project.name)),
                ]),
                _c("div", { staticClass: "desc" }, [
                  _c("span", [_vm._v(_vm._s(_vm.project.ref))]),
                ]),
              ]),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }