var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("placeholder-block", {
    attrs: { label: "Agreement", block: _vm.block, can_delete: false },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }