var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-card",
    { staticClass: "content-block text", attrs: { shadow: "never" } },
    [
      _vm.editingBlock
        ? [
            _c(
              "div",
              {
                staticClass: "heading",
                attrs: { slot: "header" },
                slot: "header",
              },
              [
                _c("div", { staticClass: "grip" }, [_vm._v("⣿")]),
                _c("el-input", {
                  ref: "heading",
                  class: { invalid: _vm.headingInvalid },
                  attrs: {
                    readonly: _vm.read_only || (!_vm.is_template && _vm.locked),
                    placeholder: "Block heading",
                  },
                  on: { input: _vm.updateBlock },
                  model: {
                    value: _vm.editingBlock.heading,
                    callback: function ($$v) {
                      _vm.$set(_vm.editingBlock, "heading", $$v)
                    },
                    expression: "editingBlock.heading",
                  },
                }),
                !_vm.read_only &&
                (_vm.is_template || _vm.editingBlock.mandatory)
                  ? _c(
                      "el-tooltip",
                      { attrs: { content: _vm.lockTooltip } },
                      [
                        _c("el-button", {
                          staticClass: "control",
                          attrs: {
                            icon: _vm.locked
                              ? "el-icon-lock"
                              : "el-icon-unlock",
                            plain: "",
                            type: _vm.locked ? null : "warning",
                          },
                          on: { click: _vm.toggleLocked },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                !_vm.read_only
                  ? _c("el-button", {
                      staticClass: "control",
                      attrs: {
                        plain: "",
                        type: "danger",
                        icon: "el-icon-delete",
                        disabled:
                          (!_vm.is_template && _vm.locked) || _vm.loading,
                      },
                      on: { click: _vm.deleteBlock },
                    })
                  : _vm._e(),
              ],
              1
            ),
            !_vm.read_only
              ? _c(
                  "div",
                  { staticClass: "prompt" },
                  [
                    _c("el-input", {
                      ref: "prompt_editor",
                      class: {
                        invalid: _vm.promptInvalid,
                      },
                      attrs: {
                        readonly:
                          (!_vm.is_template && _vm.locked) || _vm.loading,
                        placeholder: "AI Prompt",
                      },
                      on: { input: _vm.updateBlock },
                      model: {
                        value: _vm.editingBlock.prompt,
                        callback: function ($$v) {
                          _vm.$set(_vm.editingBlock, "prompt", $$v)
                        },
                        expression: "editingBlock.prompt",
                      },
                    }),
                    _c(
                      "el-select",
                      {
                        staticClass: "select-mode",
                        attrs: { disabled: _vm.loading },
                        on: { change: _vm.updateBlock },
                        model: {
                          value: _vm.editingBlock.mode,
                          callback: function ($$v) {
                            _vm.$set(_vm.editingBlock, "mode", $$v)
                          },
                          expression: "editingBlock.mode",
                        },
                      },
                      [
                        !_vm.is_template
                          ? _c(
                              "el-tooltip",
                              {
                                attrs: {
                                  content:
                                    "Text is based on the prompt alone. ",
                                  placement: "left",
                                },
                              },
                              [
                                _c("el-option", {
                                  attrs: {
                                    value: _vm.DOCUMENT_AI_MODES.PROMPT_ONLY,
                                    label: "Prompt only",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        !_vm.is_template
                          ? _c(
                              "el-tooltip",
                              {
                                attrs: {
                                  content:
                                    "Generated text will consider all non-ai text in the document.",
                                  placement: "left",
                                },
                              },
                              [
                                _c("el-option", {
                                  attrs: {
                                    value:
                                      _vm.DOCUMENT_AI_MODES.SUMMARISE_DOCUMENT,
                                    label: "Summarise document",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                    !_vm.is_template
                      ? _c(
                          "el-tooltip",
                          {
                            attrs: {
                              content: _vm.textGeneratorTooltip,
                              placement: "top",
                            },
                          },
                          [
                            _c(
                              "el-popconfirm",
                              {
                                attrs: {
                                  title:
                                    "Replace text block with AI generated text?",
                                  disabled: _vm.loading,
                                },
                                on: { confirm: _vm.handleAIGenerateText },
                              },
                              [
                                _c("el-button", {
                                  staticClass: "control",
                                  attrs: {
                                    slot: "reference",
                                    icon: "el-icon-refresh",
                                    disabled:
                                      _vm.is_template ||
                                      _vm.loading ||
                                      _vm.promptInvalid,
                                    plain: "",
                                  },
                                  slot: "reference",
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
            _c("text-editor", {
              ref: "content_editor",
              class: {
                invalid: _vm.contentInvalid,
                hidden: _vm.is_template || _vm.loading,
              },
              attrs: {
                editable: !_vm.read_only,
                content: _vm.editingBlock.content,
                placeholder: "Content",
                floating_menu: false,
                drag_drop: false,
              },
              on: {
                "update:content": [
                  function ($event) {
                    return _vm.$set(_vm.editingBlock, "content", $event)
                  },
                  _vm.updateBlock,
                ],
              },
            }),
            _vm.loading ? _c("div") : _vm._e(),
          ]
        : [
            _c(
              "div",
              { attrs: { slot: "header" }, slot: "header" },
              [_c("el-skeleton", { attrs: { rows: 1, animated: "" } })],
              1
            ),
            _c("el-skeleton", { attrs: { rows: 3, animated: "" } }),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }