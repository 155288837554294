var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.is_template
    ? _c(
        "div",
        [
          _c("placeholder-block", {
            attrs: {
              label: "Estimate Summary",
              block: _vm.block,
              can_delete: false,
            },
          }),
        ],
        1
      )
    : _c(
        "div",
        [
          _c(
            "el-card",
            {
              staticClass: "content-block estimate-summary",
              attrs: { shadow: "never" },
            },
            [
              _c(
                "div",
                {
                  staticClass: "heading",
                  attrs: { slot: "header" },
                  slot: "header",
                },
                [
                  _c("div", { staticClass: "grip" }, [_vm._v("⣿")]),
                  _c("span", { staticClass: "label" }, [
                    _vm._v("Estimate summary"),
                  ]),
                  _vm.estimate
                    ? _c(
                        "div",
                        { staticClass: "controls" },
                        [
                          _c("span", [_vm._v("Options:")]),
                          _c(
                            "el-select",
                            {
                              attrs: {
                                disabled: _vm.read_only,
                                multiple: "",
                                placeholder: "Select",
                              },
                              on: {
                                change: (val) =>
                                  _vm.$emit("update", {
                                    ..._vm.block,
                                    values: val,
                                  }),
                              },
                              model: {
                                value: _vm.values,
                                callback: function ($$v) {
                                  _vm.values = $$v
                                },
                                expression: "values",
                              },
                            },
                            [
                              _c(
                                "el-option-group",
                                { key: "toggles", attrs: { label: "Toggles" } },
                                [
                                  _vm.hasExcludedGroups || _vm.hasExcludedTasks
                                    ? _c("el-option", {
                                        attrs: {
                                          disabled:
                                            !_vm.hasExcludedGroups &&
                                            !_vm.showTasks,
                                          label: "Show excluded",
                                          value:
                                            _vm.DOCUMENT_SUMMARY_TOGGLES
                                              .EXCLUDED,
                                        },
                                      })
                                    : _vm._e(),
                                  _c("el-option", {
                                    attrs: {
                                      disabled: !_vm.showTasks,
                                      label: "Show task time/cost",
                                      value:
                                        _vm.DOCUMENT_SUMMARY_TOGGLES.TASK_COST,
                                    },
                                  }),
                                  _c("el-option", {
                                    attrs: {
                                      label: "Show GST",
                                      value: _vm.DOCUMENT_SUMMARY_TOGGLES.GST,
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "el-option-group",
                                { key: "display", attrs: { label: "Display" } },
                                [
                                  _c("el-option", {
                                    attrs: {
                                      label: "Tasks",
                                      value: _vm.DOCUMENT_SUMMARY_DETAIL.TASKS,
                                    },
                                  }),
                                  _c("el-option", {
                                    attrs: {
                                      label: "Days",
                                      value: _vm.DOCUMENT_SUMMARY_DETAIL.DAYS,
                                    },
                                  }),
                                  _c("el-option", {
                                    attrs: {
                                      label: "Hours",
                                      value: _vm.DOCUMENT_SUMMARY_DETAIL.HOURS,
                                    },
                                  }),
                                  _c("el-option", {
                                    attrs: {
                                      label: "Cost",
                                      value: _vm.DOCUMENT_SUMMARY_DETAIL.COST,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              ),
              !_vm.estimate
                ? _c("el-skeleton", { attrs: { rows: 3, animated: "" } })
                : _c(
                    "table",
                    { staticClass: "summary-table" },
                    [
                      _c("tr", [
                        _c("th", { staticClass: "col-activity" }, [
                          _vm._v("Activity"),
                        ]),
                        !_vm.showDays && !_vm.showHours && !_vm.showCost
                          ? _c("th", { staticClass: "col-value" })
                          : _vm._e(),
                        _vm.showDays
                          ? _c("th", { staticClass: "col-value" }, [
                              _vm._v("Days"),
                            ])
                          : _vm._e(),
                        _vm.showHours
                          ? _c("th", { staticClass: "col-value" }, [
                              _vm._v("Hours"),
                            ])
                          : _vm._e(),
                        _vm.showCost
                          ? _c("th", { staticClass: "col-value" }, [
                              _vm._v("Cost"),
                            ])
                          : _vm._e(),
                      ]),
                      _vm._l(_vm.filteredGroups, function (group) {
                        return [
                          _c(
                            "tr",
                            {
                              key: group.id,
                              class: {
                                excluded: group.excluded_from_summary,
                                tasks: _vm.showTasks,
                                "task-cost": _vm.showTasks && _vm.showTaskCost,
                              },
                            },
                            [
                              _c("td", { staticClass: "col-activity" }, [
                                _vm._v(" " + _vm._s(group.title) + " "),
                                group.discount
                                  ? _c(
                                      "div",
                                      { staticClass: "discount-note" },
                                      [
                                        _vm._v(
                                          " Includes " +
                                            _vm._s(group.discount) +
                                            "% discount "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]),
                              !_vm.showDays && !_vm.showHours && !_vm.showCost
                                ? _c("td", { staticClass: "col-value" })
                                : _vm._e(),
                              _vm.showDays
                                ? _c(
                                    "td",
                                    {
                                      staticClass: "col-value",
                                      attrs: {
                                        rowspan:
                                          _vm.showTasks && !_vm.showTaskCost
                                            ? _vm.getFilteredTasks(group)
                                                .length + 1
                                            : 1,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.getGroupDays(group).toFixed(1)
                                          ) +
                                          " "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm.showHours
                                ? _c(
                                    "td",
                                    {
                                      staticClass: "col-value",
                                      attrs: {
                                        rowspan:
                                          _vm.showTasks && !_vm.showTaskCost
                                            ? _vm.getFilteredTasks(group)
                                                .length + 1
                                            : 1,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.getGroupHours(group).toFixed(1)
                                          ) +
                                          " "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm.showCost
                                ? _c(
                                    "td",
                                    {
                                      staticClass: "col-value",
                                      attrs: {
                                        rowspan:
                                          _vm.showTasks && !_vm.showTaskCost
                                            ? _vm.getFilteredTasks(group)
                                                .length + 1
                                            : 1,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm._f("currency")(
                                              _vm.getGroupCost(group)
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          ),
                          _vm.showTasks
                            ? _vm._l(_vm.getFilteredTasks(group), function (
                                task
                              ) {
                                return _c(
                                  "tr",
                                  {
                                    key: task.id,
                                    class: {
                                      excluded:
                                        group.excluded_from_summary ||
                                        task.excluded_from_summary,
                                    },
                                  },
                                  [
                                    _c(
                                      "td",
                                      { staticClass: "col-activity task" },
                                      [
                                        _vm._v(
                                          " " + _vm._s(task.description) + " "
                                        ),
                                      ]
                                    ),
                                    !_vm.showDays &&
                                    !_vm.showHours &&
                                    !_vm.showCost
                                      ? _c("td", { staticClass: "col-value" })
                                      : _vm._e(),
                                    _vm.showTaskCost
                                      ? [
                                          _vm.showDays
                                            ? _c(
                                                "td",
                                                { staticClass: "col-value" },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm
                                                          .getTaskDays(task)
                                                          .toFixed(1)
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm.showHours
                                            ? _c(
                                                "td",
                                                { staticClass: "col-value" },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm
                                                          .getTaskHours(task)
                                                          .toFixed(1)
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm.showCost
                                            ? _c(
                                                "td",
                                                { staticClass: "col-value" },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm._f("currency")(
                                                          task.cost
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      : _vm._e(),
                                  ],
                                  2
                                )
                              })
                            : _vm._e(),
                        ]
                      }),
                      _vm.showDays || _vm.showHours
                        ? _c("tr", [
                            _c(
                              "td",
                              { staticClass: "total-days-label right" },
                              [_vm._v(" Total time ")]
                            ),
                            _vm.showDays
                              ? _c("td", { staticClass: "total-days right" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.totalDays?.toFixed(1)) +
                                      " "
                                  ),
                                ])
                              : _vm._e(),
                            _vm.showHours
                              ? _c("td", { staticClass: "total-days right" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.totalHours?.toFixed(1)) +
                                      " "
                                  ),
                                ])
                              : _vm._e(),
                            _vm.showCost
                              ? _c("td", { staticClass: "total-days right" })
                              : _vm._e(),
                          ])
                        : _vm.showGST && !_vm.creditValue
                        ? _c("tr", [
                            _c("td", {
                              staticClass: "spacer",
                              attrs: { colspan: _vm.columns + 1 },
                            }),
                          ])
                        : _vm._e(),
                      _vm.creditValue > 0
                        ? [
                            _c("tr", [
                              _c(
                                "td",
                                {
                                  staticClass: "total-cost-label right",
                                  attrs: { colspan: _vm.columns },
                                },
                                [_vm._v(" Subtotal ")]
                              ),
                              _c("td", { staticClass: "total-cost right" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm._f("currency")(_vm.totalGroupCost)
                                    ) +
                                    " "
                                ),
                              ]),
                            ]),
                            _c("tr", [
                              _c(
                                "td",
                                {
                                  staticClass: "right",
                                  attrs: { colspan: _vm.columns },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.estimate.credit.label) +
                                      " "
                                  ),
                                ]
                              ),
                              _c("td", { staticClass: "right" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm._f("currency")(_vm.creditValue)
                                    ) +
                                    " "
                                ),
                              ]),
                            ]),
                          ]
                        : _vm._e(),
                      _vm.showGST
                        ? [
                            _c("tr", [
                              _c(
                                "td",
                                {
                                  staticClass: "right",
                                  attrs: { colspan: _vm.columns },
                                },
                                [_vm._v(" Subtotal ")]
                              ),
                              _c("td", { staticClass: "right" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm._f("currency")(_vm.totalCost)) +
                                    " "
                                ),
                              ]),
                            ]),
                            _c("tr", [
                              _c(
                                "td",
                                {
                                  staticClass: "right",
                                  attrs: { colspan: _vm.columns },
                                },
                                [_vm._v(" GST ")]
                              ),
                              _c("td", { staticClass: "right" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm._f("currency")(_vm.totalGST)) +
                                    " "
                                ),
                              ]),
                            ]),
                          ]
                        : _vm._e(),
                      _c("tr", [
                        _c(
                          "td",
                          {
                            staticClass: "total-cost-label right",
                            attrs: { colspan: _vm.columns },
                          },
                          [
                            !_vm.showGST
                              ? _c("span", { staticClass: "tax-note" }, [
                                  _vm._v("ex. GST"),
                                ])
                              : _vm._e(),
                            _vm._v(" Total "),
                          ]
                        ),
                        _c("td", { staticClass: "total-cost right" }, [
                          _vm.showGST
                            ? _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm._f("currency")(
                                        _vm.totalCost + _vm.totalGST
                                      )
                                    ) +
                                    " "
                                ),
                              ])
                            : _c("span", [
                                _vm._v(
                                  _vm._s(_vm._f("currency")(_vm.totalCost))
                                ),
                              ]),
                        ]),
                      ]),
                    ],
                    2
                  ),
            ],
            1
          ),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }